import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogOptions, PromptDialogService } from '@lc/core';
import {
  Input, InputForm, LoadingEvent, Masks,
} from '@lc/ui';

export class SurveyInputFieldConfig {
  input?: InputForm;
}

@Component({
  selector: 'lc-survey-input-field',
  templateUrl: './survey-input-field.component.html',
  styleUrls: ['./survey-input-field.component.scss'],
  standalone: false,
})
export class SurveyInputFieldComponent {
  readonly form = new InputForm();
  readonly header!: string;
  readonly spinner = LoadingEvent.none;
  readonly MASKS = Masks;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SurveyInputFieldComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data: SurveyInputFieldConfig,
  ) {
    if (this.data) {
      this.form.reset(data.input);
      this.header = data.input ? 'Edit Survey Question' : 'New Survey Question';
    }
  }

  async onSave() {
    if (this.form.invalid) {
      return this.form.markAllAsDirty();
    }

    this.dialogRef?.close(this.form.value);
  }

  async onCancel() {
    this.dialogRef?.close();
  }

  static async open(
    service: PromptDialogService,
    data: SurveyInputFieldConfig,
    options?: IDialogOptions,
  ): Promise<Input | undefined> {
    const defaultOptions: IDialogOptions = {
      width: '750px', disableClose: false, panelClass: 'p-0', autoFocus: false,
    };
    const opts: IDialogOptions = Object.assign(defaultOptions, options);
    return service.openComponent<SurveyInputFieldComponent, SurveyInputFieldConfig, Input>(SurveyInputFieldComponent, data, opts);
  }
}
