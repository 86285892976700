import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
  CardsModule, CheckboxModule, DynamicFormModule, FormButtonsModule, HeaderModule,
} from '@lc/ui';

// Checkout Components
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SurveyListComponent } from './survey-list.component';

const angularModules = [CommonModule, MatTableModule, MatPaginatorModule, MatTooltipModule, MatIconModule, MatButtonModule, RouterModule, FormsModule];
const lcModules = [
  CardsModule, HeaderModule, FormButtonsModule, DynamicFormModule, CheckboxModule,
];
const externalComponents = [SurveyListComponent];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [...angularModules, ...lcModules, MatCheckboxModule, MatProgressSpinnerModule],
})
export class SurveyListModule {}
